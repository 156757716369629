import React, { Component } from 'react'

import QuoteCarousel from '../QuoteCarousel'
import ImageCarousel from '../Components/ImageCarousel'
import ImageContainer from '../Components/ImageContainer'
import './LilSaffronPage.scss'

export default class LilSaffronPage extends Component {
    constructor(props) {
	super(props)

	this.photoGallery = [
	    { url: '../../assets/lil_saffron_page/show_1.jpeg' },
	    { url: '../../assets/lil_saffron_page/show_2.jpeg' },
	    { url: '../../assets/lil_saffron_page/show_3.jpeg' },
	    { url: '../../assets/lil_saffron_page/show_4.jpeg' },
	    { url: '../../assets/lil_saffron_page/show_5.jpeg' },
	    { url: '../../assets/lil_saffron_page/option_4_gold.png' },
	    { url: '../../assets/lil_saffron_page/IMG_1981.JPG' },
	    { url: '../../assets/lil_saffron_page/IMG_1866.JPG' },
	    { url: '../../assets/lil_saffron_page/IMG_1760.JPG' },
	    { url: '../../assets/lil_saffron_page/Lil Saffron Ragu to Riches FINAL POSTER.png' },
	    ]
    }



    render() {

	const logoContainer = <div className='lil-saffron-page__container__about__logos'>
				  <ImageContainer containerClass='logo' id='soho-labs' />
				  <ImageContainer containerClass='logo' id='just-the-tonic' />
			      </div>

	
	const descriptionContainer = <div className='lil-saffron-page__container__description'>
					 <h2>Lil Saffron: Ragu to Riches</h2>
					 <p>A hip hop musical comedy about a pasta chef chasing his dreams of culinary greatness.</p>
					 <p>Written and Performed by Michael Kunze.</p>
					 <p>Additional Music by Hugh Edwards.</p>
					 <p>2023 Soho Theatre</p>
					 <p>2022 Pleasance Theatre</p>
					 <p>2022 Edinburgh Fringe - Just the Tonic</p>
					 <p>2021 Camden Fringe - Hen and Chickens</p>
					 <p>⭐⭐⭐⭐⭐ and ⭐⭐⭐⭐⭐ EdFringeReview</p>
					 <p>⭐⭐⭐⭐ Musical Theatre Review</p>
					 {logoContainer}
					 
				     </div>


	const quotesText = this.props.quotes.filter(quote => { return quote.about == "Lil Saffron: Ragu to Riches" })

    	return <div className='lil-saffron-page'>
		   <div className='lil-saffron-page__container'>
		       <ImageContainer id="lil-saffron-main-poster" />
		       <div className='lil-saffron-page__container__about'>
			   <QuoteCarousel quoteText={quotesText} carouselTitle='lil-saffron-quotes' parentPage='lil-Saffron' currentPage={this.props.currentPage}/>

			   {descriptionContainer}
		       </div>
		   </div>
		   <ImageCarousel photoLinks={this.photoGallery} carouselId='saffron-gallery' headline="Production Gallery"/>
		   <h2 id='feedback-headline'>Audience Feedback</h2>
		   <ImageContainer containerClass="feedback" id='audience-feedback'/>
		   <ImageContainer containerClass="feedback" id='twitter-feedback'/>
	       </div>
    }
}
