import React, { Component } from 'react'

export default class NewsBanner extends Component {
    constructor(props) {
	super(props) 

	this.state = {
	    closed: false
	}

	this.closeBanner = this.closeBanner.bind(this)
	this.clickBanner = this.clickBanner.bind(this)
    }
    
    closeBanner(event) {
	this.setState({closed: true})
    }
    
    clickBanner(event) {
	const { onClick, linkUrl } = this.props
	if (onClick) onClick()
	else window.open(linkUrl)
    }

    render() {
	const { text } = this.props
	const { closed } = this.state

	if (closed) return
	
	const closeBannerIcon = <i className='fa fa-times' onClick={this.closeBanner} />
	
	return <div className='news-banner'>
		   <div className='news-banner__text' onClick={this.clickBanner}>{text}</div>
		   {closeBannerIcon}
	       </div>
    }
}
