import React, { Component } from 'react'

import './VideosPage.scss'
import VideoPage from './VideoPage.js'
import Header from '../header.js'

export default class VideosPage extends Component {
    constructor(props) {
	super(props)
	
    }

    render() {
	const { videosData } = this.props
	
	const videos = videosData.map((video, index) => {
	    const { title, src, id } = video
	    return <VideoPage title={title} id={id} src={src} key={`video-${index}`} />
	})

	const videosContainer = <div className='videos-page'>
				    <div className='videos-page__container'>
					{videos}
				    </div>
				</div>
	return videosContainer
    }
}
