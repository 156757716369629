import React, { Component } from 'react'

import ImageContainer from '../Components/ImageContainer.js'
import './GalleryPage.scss'

export default class GalleryPage extends Component {
    constructor(props) {
	super(props)

	this.state = {
	    currentImageIndex: 0
	}

	this.selectImage = this.selectImage.bind(this)
	this.images = [0,1,2,3,4,5,6,7,8]
    }

    selectImage(event) {
	console.log('changing photo')
	const currentImageIndex = parseInt(event.target.id.split('-')[1])
	this.setState({currentImageIndex})
    }

    render() {

	const images = this.images.map(image => {return <ImageContainer containerClass='gallery-image' id={`image-${image}`} onClick={this.selectImage} key={image}/> })
	
	const imagesContainer = <div className='gallery-page__right-side__images-container'>
				    {images}
				</div>

	const currentImageId = this.state.currentImageIndex
	const currentImage= <ImageContainer containerClass='gallery-page__image' id={`image-${currentImageId}`} />

	return <div className='gallery-page'>
		   {currentImage}
		   <div className='gallery-page__right-side'>
		       {imagesContainer}
	 	   </div>
	       </div>
    }


}
