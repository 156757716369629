import React, { Component } from 'react'

import QuoteCarousel from '../QuoteCarousel'
import ImageContainer from '../Components/ImageContainer.js'

import './ImprovPage.scss'

export default class ImprovPage extends Component {
    constructor(props) {
	super(props)

	this.teams = [
	    {
		title: 'Track 96 Improvised Hip Hop',
		description: "London's premier improvised hip hop improv group. From an audience suggestion we create a world of improvised freestyle rhyming.",
		link: "https://track96.com/",
		id: 'track-96',
	    },
	    {
		title: 'Michelle Impro',
		description: "We are Michelle, we do improv scenes and we live in the Hoopla Impro house.",
		link: "https://www.instagram.com/michelleimpro/?hl=en",
		id: 'michelle-impro',
	    },
	    {
		title: 'Blueberry',
		description: 'Award-winning duo of Mike Kunze and Matt Stainsby',
		link: "https://www.instagram.com/blueberryimprov/",
		id: 'blueberry',
	    },
	    {
		title: 'Film Club',
		description: 'The improvised sketch show focussed on the high-stakes scenes of your favourite movies, we bring the stage alive with authentic drama.',
		link: "https://filmclubimprov.com/",
		id: 'film-club',
	    },
	    {
		title: 'Crime Scene Improvisation',
		link: "https://www.crimesceneimpro.com/",
		description: 'Crime Scene Improvisation is a rip-roaring crime comedy entirely made up on the spot to solve a surreal murder chosen by you, the audience.',
		id: 'csi',
	    },
/*	    {
		title: 'Just Friends',
		description: 'Imrov booyah',
		link: "https://www.instagram.com/justfriendsyeah/?hl=en",
		id: 'just-friends',
	    },*/
	    {
		title: 'Auto-Correbt',
		description: "One of Ireland's top longform improv teams",
		link: "https://www.instagram.com/autocorrebt/?hl=en",
		id: 'auto-correbt',
	    }
	]

	this.quotes = [
	    {
		name: "bunbury-csi",
		link: "",
		text: "★★★★★ Quick thinking & fast paced. A spectacular must see.",
		source: "Bunbury Magazine",
		about: "Crime Scene Impro"
	    },
	       {
		name: "wee-review-csi",
		link: "",
		text: "★★★★★ They have improvisation down to a fine art",
		source: "Wee Review",
		about: "Crime Scene Impro"
	    },
	       {
		name: "fringe-biscuit-csi",
		link: "",
		text: "★★★★ Doubles as a hysterical murder mystery & a tight witty improv show.",
		source: "Fringe Biscuit",
		about: "Crime Scene Impro"
	    },
	       {
		name: "blueberry-steve",
		link: "",
		text: "Blueberry are awesome! They combine fantastic storytelling with fun characters and most of all really funny scenes. They have a rare ability to combine narrative with great comedy all made up on the spot.",
		source: "Steve Roe, founder of Hoopla Impro",
		about: "Blueberry Improv"
	    },
	       {
		name: "blueberry-graham",
		link: "",
		   text: "This is some seriously juicy improv. Blueberry have amazing chemistry. They are inventive, bold and hilarious - not to mention delicious and nutritious!",
		source: "Graham Dickson, co-founder of Austentatious",
		about: "Blueberry Improv"
	    },
	    {
		name: "blueberry-phoenix-remix",
		link: "https://thephoenixremix.com/2019/12/28/the-phoenix-remix-awards-2019-interview-best-new-improv-troupe-blueberry/",
		text: "WINNER - BEST NEW IMPROV TROUPE Their narrative skills and character tehniques makes them very unique.",
		source: "Phoenix Remix",
		about: "Blueberry Improv"
	    },
	    {
		name: "michelle-reviewshub",
		link: "https://www.thereviewshub.com/brighton-fringe-hotel-michelle-laughing-horse-the-walrus-raised-room/",
		text: "Downright hilarity in this feel good improvised feast.",
		source: "ReviewsHub",
		about: "Michelle Impro"
	    },
	    {
		name: "michelle-morning-star",
		link: "https://morningstaronline.co.uk/article/c/send-clowns-james-walsh-august-30",
		text: "Michelle know what they’re doing, even if, often, they quite literally don’t know what they’re doing.",
		source: "Morning Star",
		about: "Michelle Impro"
	    },
	    {
		name: "track-96-steve",
		link: "",
		text: "One of the slickest groups on the scene",
		source: "Steve Roe, founder of Hoopla Impro",
		about: "Track 96"
	    }
	    
	]
    }

    

    render() {

	const description = <div className='improv-page__container__description'><p>Michael Kunze is an award winning improviser with years of experience performing with some of the best groups in the UK and Ireland. He teaches hip hop improv through Hoopla Impro. He has trained with Hoopla Impro, the Free Association, and performs regularly in London and throughout the UK.</p></div>

	const teams = this.teams.map((item, i) => {
	    const { title, description, link, id } = item
	    const onClick = event => { window.open(link) }
	    
	    const overlay = <div className='improv-page__container__team-container__team__overlay' onClick={onClick}>
				<div className='improv-page__container__team-container__team__overlay__name'>{title}</div>
				<p className='improv-page__container__team-container__team__overlay__description'>{description}</p>
			    </div>
	    const imageContainer = <ImageContainer containerClass='improv-team' id={`image-${id}`} />
	    const team = <div className='improv-page__container__team-container__team' key={`team-${id}`}>
			     {imageContainer}
			     {overlay}
			 </div>
	    return team
	})
	
	const teamContainer = <div className='improv-page__container__team-container'>
				  {teams}
			      </div>
	
	return <div className='improv-page'>
		   <div className='improv-page__container'>
		       {description}
		       <QuoteCarousel quoteText={this.quotes} showAbout={true} carouselTitle='improv-quotes' parentPage='improv' currentPage={this.props.currentPage}/>
		       {teamContainer}
		   </div>
	       </div>
    }
    
}
