import React, { Component } from 'react'

export default class Header extends Component {
    constructor(props) {
	super(props)

	this.state = {
	    showHeader: false,
	    dropdownShowing: ''
	}
	
	this.clickPage = this.clickPage.bind(this)
	this.clickCaret = this.clickCaret.bind(this)
	this.buildPage = this.buildPage.bind(this)
	this.buildDropdown = this.buildDropdown.bind(this)
	this.toggleDropdown = this.toggleDropdown.bind(this)


	//this.pages = ['home', 'videos', 'live', 'shows', 'lil Saffron', 'infinity Mirror', 'exile', 'improv', 'contact','blue Heart Theatre', 'gallery' ]
	this.pages = ['home', 'videos', 'shows', 'lil Saffron', 'infinity Mirror', 'boy Band', 'exile', 'improv', 'contact','blue Heart Theatre', 'gallery' ]

	this.mobileOnly = [ 'infinity Mirror', 'lil Saffron', 'exile', 'boy Band' ]

	this.dropdowns = {
	    shows: [ 'infinity Mirror', 'lil Saffron', 'exile', 'boy Band' ]
	}

	//todo pages press
    }

    clickPage(event) {
	const id = event.target.id
	if (id == '') return
	const cb = () => { this.props.switchPage(id) }
	this.setState({showHeader: false, dropdownShowing: ''}, cb)
    }

    clickCaret(event) {
	const showHeader = !this.state.showHeader
	this.setState({showHeader})
    }

    buildDropdown(page, index) {
	const { currentPage } = this.props
	const { showHeader, dropdownShowing } = this.state
	
	const id = page.split(' ').join('-')
	const isCurrent = id == currentPage
	let className = 'header__pages-container__page-item__dropdown__item' + (isCurrent ? ' current' : '')
	
	const onClick = this.clickPage
	
	return <div id={id} className={className} onClick={onClick} key={page}>{`${page[0].toUpperCase()}${page.substring(1)}`}</div>
    }

    toggleDropdown(dropdownShowing) {
	if (this.state.dropdownShowing == dropdownShowing) dropdownShowing = ''
	this.setState({dropdownShowing})
    }
    
    buildPage(page, index) {
	const { currentPage } = this.props
	const { showHeader, dropdownShowing } = this.state
	
	const id = page.split(' ').join('-')
	const isCurrent = id == currentPage
	let className = 'header__pages-container__page-item' + (isCurrent ? ' current' : '')
	if (this.mobileOnly.includes(page)) className += ' mobile-only'
	const isDropdown = Object.keys(this.dropdowns).includes(page)
	if (isDropdown) className += ' dropdown'

	const onClick = isDropdown ? () => { this.toggleDropdown(page) } : this.clickPage
	
	const isDropdownShowing = dropdownShowing == page
	const toggleDropdownButtonClass = isDropdownShowing ? 'fa fa-caret-up' : 'fa fa-caret-down'
	const toggleDropdownButton = isDropdown ? <i className={toggleDropdownButtonClass} onClick={onClick} /> : null
	const dropdownChildren = isDropdown && isDropdownShowing ? this.dropdowns[page].map(this.buildDropdown) : null
	
	return <div className={className} onClick={onClick} key={page}>
		   <div className='header__pages-container__page-item__title' id={id} >
		       {`${page[0].toUpperCase()}${page.substring(1)}`}
		       {toggleDropdownButton}
		   </div>
		   <div className='header__pages-container__page-item__dropdown'>
		       {dropdownChildren}
		   </div>
	       </div>
	

    }

    render() {
	const { currentPage } = this.props
	const { showHeader, dropdownShowing } = this.state

	const pages = this.pages.map(this.buildPage)

/*	const morePages = showMore ? this.more.map(page => {
	    const id = page.split(' ').join('-')
	    const onClick = id == 'back' ? this.toggleMore : this.clickPage
	    const text = id == 'back' ? <i className='fa fa-arrow-left' /> : `${page[0].toUpperCase()}${page.substring(1)}` 
	    return <span className={id == currentPage ? 'current' : ''} id={id} onClick={onClick} key={page}>{text}</span>
	    
	}) : null

	const moreContainer = showMore ? <div className="header__more-container">
					     {morePages}
					 </div> : null*/

	const headerToggleClass = showHeader ? 'fa fa-caret-up' : 'fa fa-caret-down'
	const nameHeader = <div className='header__name-header'>
			       <h1 id='name-header'>Michael Kunze</h1>
			       <i className={headerToggleClass} id='header-toggle' onClick={this.clickCaret}/>
			   </div>

	const pagesContainerClass = showHeader ? "header__pages-container show" : "header__pages-container"
	
	return <div className="header">
		   {nameHeader}
		   <div className={pagesContainerClass}>
		       {pages}
		   </div>

	       </div>
    }
}
