import React, { Component } from 'react'

import QuoteCarousel from '../QuoteCarousel'
import ImageCarousel from '../Components/ImageCarousel'
import ImageContainer from '../Components/ImageContainer'

import './ExilePage.scss'

export default class ExilePage extends Component {
    constructor(props) {
	super(props)

	this.photoGallery = [
	    { url: '../../assets/lil_saffron_page/show_1.jpeg' },
	    { url: '../../assets/lil_saffron_page/show_2.jpeg' },
	    { url: '../../assets/lil_saffron_page/show_3.jpeg' },
	    { url: '../../assets/lil_saffron_page/show_4.jpeg' },
	    { url: '../../assets/lil_saffron_page/show_5.jpeg' },
	    { url: '../../assets/lil_saffron_page/option_4_gold.png' },
	    { url: '../../assets/lil_saffron_page/IMG_1981.JPG' },
	]
    }


    
    render() {

	const logoContainer = <div className='exile-page__container__about__logos'>
				  <ImageContainer containerClass='logo' id='blue-heart-theatre' />
				  <ImageContainer containerClass='logo' id='off-comm' />
			      </div>

	
	const descriptionContainer = <div className='exile-page__container__description'>
					 <h2>Exile</h2>
					 <p>Directed by Michael Kunze</p>
					 <p>Written by Niamh Denyer</p>
					 <p>Performed by Niamh Denyer and Samuel Johnson</p>
					 <p>Produced by Blue Heart Theatre & JoMac Productions</p>
					 <p>Set against the backdrop of the criminalization of abortion in Ireland, Exile examines the idea of home and identity and the choices one must make when thetwo collide. A monologue play for two that explores an all too recent history, it is a frank and honest portrayal of the journey one must take when confronted with hard truths about themselves and their place within their home.</p>
				
					 <p>2021 Southwark Playhouse</p>
					 <p>2021 Lion & Unicorn</p>
					 <p>2019 Kings Head Theatre</p>
					 <p>⭐⭐⭐⭐ ReviewsHub ⭐⭐⭐⭐ Everything Theatre</p>
					 {logoContainer}
					 				     </div>


	const quotesText = this.props.quotes.filter(quote => { return quote.about == "Exile" })

    	return <div className='exile-page'>
		   <div className='exile-page__container'>
		       <ImageContainer id="exile-main-poster" />
		       <div className='exile-page__container__about'>
			   <QuoteCarousel quoteText={quotesText} carouselTitle='exile-quotes' currentPage={this.props.currentPage} parentPage='exile'/>
			   {descriptionContainer}
		       </div>
		   </div>
		   <ImageCarousel photoLinks={this.photoGallery} carouselId='exile-gallery' headline="Production Gallery"/>
	       </div>
    }
}
