import React, { Component } from 'react'

import QuoteCarousel from '../QuoteCarousel.js'
import VideoPage from './VideoPage.js'

import ImageContainer from '../Components/ImageContainer.js'


export default class HomePage extends Component {
    constructor(props) {
	super(props)
    }

    render() {
	const { socials, videos, quoteCarouselText, underConstruction, currentPage, learnMoreOnClick } = this.props

	const socialLinks = socials.map((social, index) => {
	    let { name, link } = social
	    //if (name == 'spotlight') name = 'star'
	    const onClick = event => { window.open(link) }
	    return <i className={`fa fa-${name}`} onClick={onClick} key={`social-link-${index}`}/>
	})
	
	const socialsContainer = <div className='home-page__container__socials'>
				     {socialLinks}
				     <a href="mailto:mikekunze@gmail.com?subject = Website email form"><i className='fa fa-envelope'/></a>
				 </div>


	if (underConstruction) return <div className='home-page'>
		   			  <div className='home-page__container'>
					      <ImageContainer id='main-image'/>
					      <div className='home-page__container__about'>
						  <h2 id="tag">Solo show "Lil Saffron: Ragu to Riches" at the Pleasance in London on November 12th</h2>
						  <h3>Actor, Writer, Director & Improviser</h3>			 
						  {socialsContainer}
					      </div>
					  </div>
				      </div>


	const videoItems = videos.map((video, index) => {
	    const { title, src, id } = video
	    return <VideoPage title={title} id={id} src={src} key={`video-${index}`}/>
	})

	return <div className='home-page'>
		   <div className='home-page__container'>
		       <ImageContainer id='main-image'/>
		       <div className='home-page__container__about'>
			   <QuoteCarousel quoteText={quoteCarouselText} carouselTitle='main-quotes' currentPage={this.props.currentPage} parentPage='home'/>
			   <h2 id="tag">Solo Debut "Infinity Mirror" at Edinburgh Fringe  Aug 1 - 25.</h2>
			   <div className='home-page__container__about__learn-more' onClick={learnMoreOnClick}>Learn More!</div>
			   <h3 id="tag">OffComm winning American actor, writer, director & improviser based in London.</h3>
			   <h3 id="tag">Member of first ever Torino Film Lab ComedyLab. <a id="tfl" target="_blank" href="https://www.screendaily.com/news/torinofilmlab-unveils-selection-for-first-ever-comedylab-feature-development-programme-exclusive/5192008.article?referrer=RSS">Read more</a></h3>
			   <h3 id="tag">Named to 21 New Acts to Watch at Edinburgh Fringe 2024 by Edinburgh Evening News / Scotsman. <a id="21-acts" target="_blank" href="https://www.edinburghnews.scotsman.com/whats-on/edinburgh-festival-fringe-2024-21-exciting-new-acts-making-their-fringe-debuts-this-year-4669364?page=3">Read more</a></h3>
			   {socialsContainer}
		       </div>
		   </div>
		   {videoItems[0]}
		   {videoItems[1]}
		   {videoItems[2]}
		   {videoItems[4]}
		   {videoItems[5]}
	       </div>
    }
}
