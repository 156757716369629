import React, { Component } from 'react'

import './QuoteCarousel.scss'

export default class QuoteCarousel extends Component {
    constructor(props) {
	super(props)

	this.state = {
	    currentQuoteIndex: 0,
	    carouselInterval: null
	}

	this.interval = 4000

	this.updateQuote = this.updateQuote.bind(this)
    }

    componentDidMount() {
	const carouselInterval = setInterval(this.updateQuote, this.interval)
	this.setState({carouselInterval})
    }


    componentWillUnmount() {
	clearInterval(this.state.carouselInterval)
    }

    updateQuote() {
	const { carouselTitle, quoteText } = this.props
	const element = document.getElementById(carouselTitle)
	if (element) {
	    const currentPosition = element.scrollLeft
	    const scrollWidth = element.scrollWidth
	    const itemWidth = element.scrollWidth / quoteText.length
	    const newPosition = currentPosition + itemWidth
	    const scrollLeft = newPosition >= scrollWidth ? 0 : newPosition
	    element.scrollTo({top: 0, left: scrollLeft, behavior: "smooth"})
	} else {
	    clearInterval(this.state.carouselInterval)
	    this.setState({carouselInterval: null})
	}
    }

    render() {
	const { quoteText, carouselTitle, showAbout } = this.props
	const quotes = quoteText.map((quote, i) => {
	    const { name, link, text, source, about } = quote
	    const sourceText = showAbout ? [`${source} (on `,<strong>{about}</strong>,')'] : source
	    const item = <div className="quote-carousel__item" id={name} key={`quote-${i}`}>
			     <div className="quote-carousel__item__quote">{`"${text}"`}</div>
			     <div className="quote-carousel__item__source">- {sourceText}</div>
			 </div>
	    return item
	})

	const quoteCarousel = <div className="quote-carousel" id={carouselTitle}>
				  {quotes}
			      </div>
	return quoteCarousel
    }
}
