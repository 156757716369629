import React, { Component } from 'react'

export default class ImageContainer extends Component {
    constructor(props) {
	super(props)
    }

    render() {
	const { id, containerClass, onClick } = this.props
	const className = `image-container ${containerClass}`
	return <div className={className} id={id} onClick={onClick}/>
    }
}
