import React, { Component } from 'react'

import ImageContainer from '../Components/ImageContainer.js'
import './LivePage.scss'

export default class LivePage extends Component {
    constructor(props) {
	super(props)

	this.gigs = require('./gigs.json')
    }


    render() {

	const now = new Date()

	let maxColumns = 0
	
	let gigs = this.gigs.map((gig, i) => {
	    const { start, showDetails } = gig
	    const [ date, time ] = start.split("T")
	    const year = date.substring(0,4)
	    const month = parseInt(date.substring(4,6)) - 1
	    const day = date.substring(6,8)
	    let hours = parseInt(time.substring(0,2))
	    /*
	      correcting for daylight savings time
	      if after march 26th, 2023 or before oct 30 2022, add 1 hr 
	    */
	    
	    
	    
	    const minutes = time.substring(2,4)
	    const dateTime = new Date(year, month, day, hours, minutes)
	    const dst2022 = new Date(2022, 9, 30)
	    const dst2023 = new Date(2023, 2, 26)
	    
	    if (dateTime < dst2022 || dateTime > dst2023) {
		const oldHours = dateTime.getHours()
		dateTime.setHours(oldHours + 1)
	    }
	    const tds = showDetails.map((detail,j) => { return <td key={`detail-${j}`}>{detail}</td> })
	    let dateString = dateTime.toLocaleString()
	    console.log({dateString, tds})
	    dateString = dateString.replaceAll(':00','')
	    dateString = dateString.replace(' PM','PM')
	    dateString = dateString.replace(' AM','AM')
	    dateString = dateString.replace(',','')
	    const row = [<tr key={`gig-${i}`}>
			     <td>{dateString}</td>
			     {tds}
			 </tr>, dateTime]
	    return row
	})

	gigs = gigs.sort((a,b) => {
	    return a[1] - b[1]
	})

	const upcomingGigs = gigs.filter(gig => gig[1] - now > 0)
	const upcomingRows = upcomingGigs.map((gig, i) => {
	    return gig[0]
	})

	const pastGigs = gigs.filter(gig => gig[1] - now <= 0 )
	const pastRows = pastGigs.map((gig, i) => {
	    return gig[0]
	})

	const upcomingHeader = upcomingGigs.length != 0 ? <h3>Upcoming</h3> : null
	const upcomingTable = upcomingGigs.length != 0 ? <table>
							     <thead>
								 <tr>
								     <th>Date</th>
								     <th>Title</th>
								     <th>Location</th>
								 </tr>
							     </thead>
							     <tbody>
								 {upcomingRows}
							     </tbody>
							 </table> : null

	const pastHeader = pastGigs.length != 0 ? <h3>Past</h3> : null
	const pastTable = pastGigs.length != 0 ? <table>
						     <thead>
							 <tr>
							     <th>Date</th>
							     <th>Title</th>
							     <th>Location</th>
							 </tr>
						     </thead>
						     <tbody>
							 {pastRows}
						     </tbody>
						 </table> : null


	
	return <div className='live-page'>
		   <ImageContainer id='live-page-image'/>
		   <div className='live-page__gigs'>
		       {upcomingHeader}
		       {upcomingTable}
		       {pastHeader}
		       {pastTable}
		       </div>
	       </div>
    }
    
}
