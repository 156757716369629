import React, { Component } from 'react'

import QuoteCarousel from '../QuoteCarousel'
import ImageContainer from '../Components/ImageContainer.js'

import './BlueHeartTheatrePage.scss'

export default class BlueHeartTheatrePage extends Component {
    constructor(props) {
	super(props)

	this.productions = ['exile', 'cock', 'couples_pairs', 'couples_pairs_2','baltimore_waltz','fray','mrs_toomey_house','icarus']
	
	this.quotes = [
	    {
		name: "reviews-hub-exile",
		link: "https://www.thereviewshub.com/exile-southwark-playhouse-london/",
		text: "Director Michael Kunze allows the stories to unfold naturally and without pressure to take sides in any argument, yet carefully steers us into empathy",
		source: "ReviewsHub",
		about: "Exile"
	    },
	    {
		name: "everything-theatre-exile",
		link: "https://everything-theatre.co.uk/2021/11/review-exile-lion-and-unicorn-theatre.html",
		text: "The writing, performances, soundscape, and lighting are all perfectly pitched",
		source: "Everything Theatre",
		about: "Exile"
	    },
	    {
		name: "reviews-hub-exile",
		link: "https://www.thereviewshub.com/exile-southwark-playhouse-london/",
		text: "Exile is one of those plays that keeps you thinking about its many layers long beyond the end. Home may be where the heart is, but the power of compassion breaks through all boundaries and can bring hope when you least expect it.",
		source: "ReviewsHub",
		about: "Exile"
	    },
	    {
		name: "everything-theatre-exile",
		link: "https://everything-theatre.co.uk/2021/11/review-exile-lion-and-unicorn-theatre.html",
		text: "Exile is a thought-provoking, extremely well-written piece.",
		source: "Everything Theatre",
		about: "Exile"
	    },
	    {
		name: "red-curtain-review",
		link: "",
		text: "Blue Heart Theatre call themselves an 'exciting new company' - which they are showing themselves to be",
		source: "Red Curtain Review",
		about: "Blue Heart Theatre: The Baltimore Waltz"
	    },
	    {
		name: "sunday-independent",
		link: "",
		text: "The five pieces pack a tenaciously realistic punch, and they're quite splendidly performed by the company ensemble",
		source: "Emer O'Kelly - Sunday Independent",
		about: "Blue Heart Theatre: Couples + Pairs"
	    },
	    /*{
		name: "",
		link: "",
		text: "",
		source: "",
		about: ""
	    }*/
	]
    }


    
    render() {
	const productions = this.productions.map(production => {
	    return <ImageContainer containerClass='production-image' id={production} key={production} />
	})
	const productionContainer = <div className='blue-heart-theatre-page__container__description__productions'>
					{productions}
				    </div>
	
	
	const descriptionContainer = <div className='blue-heart-theatre-page__container__description'>
					 <h2>Blue Heart Theatre</h2>
					 <p>Blue Heart Theatre was established in 2014 to promote new writing. It was founded by Michael Kunze, Niamh Denyer, Brian Higgins, and Sean Denyer.</p>
					 <p>Our award-winning work has been shown across Ireland and the UK.</p>
					 <p>For more information, please see <a href="http://www.bluehearttheatre.com/" target="_blank">our website</a>.</p>
					 {productionContainer}
				     </div>


    	return <div className='blue-heart-theatre-page'>
		   <div className='blue-heart-theatre-page__container'>
		       <ImageContainer id="blue-heart-theatre" />
		       <div className='blue-heart-theatre-page__container__about'>
			   {descriptionContainer}
			   <QuoteCarousel quoteText={this.quotes} carouselTitle='exile-quotes' parentPage='blue-Heart-Theatre' currentPage={this.props.currentPage}/>
		       </div>
		   </div>
	       </div>
    }
}
