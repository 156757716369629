import React, { Component } from 'react'

import './ContactPage.scss'
import ImageContainer from '../Components/ImageContainer.js'

export default class ContactPage extends Component {
    constructor(props) {
	super(props)

	this.state = {
	    name: '',
	    email: '',
	    subject: '',
	    body: '',
	    formSubmitted: false
	}
	
	this.handleNameInput = this.handleNameInput.bind(this)
	this.handleEmailInput = this.handleEmailInput.bind(this)
	this.handleSubjectInput = this.handleSubjectInput.bind(this)
	this.handleBodyInput = this.handleBodyInput.bind(this)
	this.clickSubmit = this.clickSubmit.bind(this)
	this.submitData = this.submitData.bind(this)

	this.awsUrl = "https://vnn0ha0qnl.execute-api.us-east-1.amazonaws.com/beta/contact-us"

    }

    handleNameInput(event) {
	const name = event.target.value
	this.setState({name})
    }

    handleEmailInput(event) {
	const email = event.target.value
	this.setState({email})
    }

    handleSubjectInput(event) {
	const subject = event.target.value
	this.setState({subject})
    }

     handleBodyInput(event) {
	const body = event.target.value
	this.setState({body})
    }
    

    submitData(data) {
	return new Promise((resolve, reject) => {
	    const xhr = new XMLHttpRequest()
	    
	    xhr.addEventListener('load', () => {
		
		let response
		
		if (xhr.readyState == xhr.DONE && xhr.status == 200) {
		    resolve(response)
		} else {
		    console.log('error')

		    if (reject) reject(response)
		    else resolve('error')
		}
	    })
	    
	    const xhrPath = this.awsUrl
	    
	    xhr.open('POST', xhrPath)
	    xhr.setRequestHeader("Content-Type", "application/json")
	    xhr.send(JSON.stringify(data))
	})
    }
    
    clickSubmit(event) {
	//this.setState({formSubmitted: true})

	//function submitToAPI(e) {
	//e.preventDefault();
	

	const { name, subject, body, email } = this.state
	
        var Namere = /[A-Za-z]{1}[A-Za-z]/;
        if (!Namere.test(name)) {
	    alert("Name can not less than 2 char");
            return;
        }
	
        if (email == "") {
            alert ("Please enter your email id");
            return;
        }
	this.submitData({name, subject, body, email}).then(resp => {
	    this.setState({formSubmitted: true})
	}).catch(err => {
	    console.log({err})
	})
	
    }


    /*
      AWS Email Stuff

      <form id="contact-form" method="post">
      <h4>Name:</h4>
      <input type="text" style="height:35px;" id="name-input" placeholder="Enter name here…" class="form-control" style="width:100%;" /><br/>
      <h4>Phone:</h4>
      <input type="phone" style="height:35px;" id="phone-input" placeholder="Enter phone number" class="form-control" style="width:100%;"/><br/>
      <h4>Email:</h4>
      <input type="email" style="height:35px;" id="email-input" placeholder="Enter email here…" class="form-control" style="width:100%;"/><br/>
      <h4>How can we help you?</h4>
      <textarea id="description-input" rows="3" placeholder="Enter your message…" class="form-control" style="width:100%;"></textarea><br/>
      <div class="g-recaptcha" data-sitekey="6Lc7cVMUAAAAAM1yxf64wrmO8gvi8A1oQ_ead1ys" class="form-control" style="width:100%;"></div>
      <button type="button" onClick="submitToAPI(event)" class="btn btn-lg" style="margin-top:20px;">Submit</button>
</form>
     */
    
    render() {
	const { socials } = this.props
	const { name, subject, body, email, formSubmitted } = this.state

        const socialLinks = socials.map((social, index) => {
	    let { name, link } = social
	    //if (name == 'spotlight') name = 'star'
	    const onClick = event => { window.open(link) }
	    return <i className={`fa fa-${name}`} onClick={onClick} key={`social-link-${index}`}/>
	})

	const socialsContainer = <div className='contact-page__container__socials'>
				     {socialLinks}
				 </div>

	let contactContainer

	if (!formSubmitted) {
	    const contactEmailInput = <div className='contact-page__container__submit-container__input-group'>
					  <label className='contact-page__container__submit-container__input-group__label'>Email</label>
					  <input className='contact-page__container__submit-container__input-group__input' id="email-input" onChange={this.handleEmailInput} placeholder='Email' value={email}/>
				      </div>
	    
	    const contactNameInput = <div className='contact-page__container__submit-container__input-group'>
					 <label className='contact-page__container__submit-container__input-group__label'>Name</label>
					 <input className='contact-page__container__submit-container__input-group__input' id="name-input" onChange={this.handleNameInput} placeholder="Full Name" value={name}/>
				     </div>
	    
	    const contactSubjectInput = <div className='contact-page__container__submit-container__input-group'>
					    <label className='contact-page__container__submit-container__input-group__label'>Subject</label>
					    <input className='contact-page__container__submit-container__input-group__input' id="subject-input" onChange={this.handleSubjectInput} placeholder="Subject" value={subject}/>
					</div>
	    
	    const contactTextArea = <div className='contact-page__container__submit-container__input-group'>
					<label className='contact-page__container__submit-container__input-group__label'>Body</label>
					<textarea className='contact-page__container__submit-container__text-area' placeholder="Your message ..." onChange={this.handleBodyInput} value={body}/>
				    </div>

	    const submitButtonActive = name != '' && subject != '' && email != '' && body != ''
	    let submitButtonClass = 'contact-page__container__submit-container__submit-button'
	    if (!submitButtonActive) submitButtonClass += ' disabled'
	    const submitButton = <button className={submitButtonClass} onClick={submitButtonActive ? this.clickSubmit : null}>Submit</button>

	    contactContainer = <div className='contact-page__container__submit-container'>
					 {contactNameInput}
					 {contactEmailInput}
					 {contactSubjectInput}
					 {contactTextArea}
					 {submitButton}
				     </div>
	} 

	const everythingElseText = formSubmitted ? 'Thank you for your submission!' : 'Get in Touch'
	const everythingElseClass =  formSubmitted ? 'extra-space' : null
	const everythingElseP = formSubmitted ? null : <p id='everything-else-copy'>If you want to collaborate on a project, or work with me as a writer or a director, or just say hi, please use the form below:</p>
	const descriptionContainer = <div className='contact-page__container__description'>
					 <h2 className={everythingElseClass}>{everythingElseText}</h2>
					 {everythingElseP}
					 {contactContainer}
					 {socialsContainer}
				     </div>
	
	
    	return <div className='contact-page'>
		   <div className='contact-page__container'>
		       <ImageContainer id="contact-image" />
		       {descriptionContainer}
		   </div>
	       </div>
    }
    
}
