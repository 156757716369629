import React, { Component } from 'react'

import './VideoPage.scss'

export default class VideoPage extends Component {
    constructor(props) {
	super(props)

	this.state = {
	    showOverlay: true,
	}
	
	this.clickPlay = this.clickPlay.bind(this)

    }

    clickPlay(event) {
	const { clickOverlay, id } = this.props 
	
	if (clickOverlay) clickOverlay(id)
	
	this.setState({showOverlay: false})
    }

    render() {

	const { title, id, src, isSelected } = this.props
	
	const overlay = this.state.showOverlay ? <div className='video-page__overlay' id={this.props.id} onClick={this.clickPlay}></div> : null
	const iframe = <iframe className="video-page__iframe" src={src} frameBorder="0" allowFullScreen></iframe>
	const videoClass = isSelected ? 'video-page selected' : 'video-page'
	return <div className={videoClass} id={`video-page-${id}`}>
		   {overlay}
		   {iframe}
	       </div>
    }

}
