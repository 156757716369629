import React, { Component } from 'react'

import Header from './header.js'
import VideosPage from './pages/VideosPage.js'
import HomePage from './pages/HomePage.js'
import LilSaffronPage from './pages/LilSaffronPage.js'
import InfinityMirrorPage from './pages/InfinityMirrorPage.js'
import LivePage from './pages/LivePage.js'
import ExilePage from './pages/ExilePage.js'
import ImprovPage from './pages/ImprovPage.js'
import BlueHeartTheatrePage from './pages/BlueHeartTheatrePage.js'
import ContactPage from './pages/ContactPage.js'
import GalleryPage from './pages/GalleryPage.js'

import NewsBanner from './Components/NewsBanner.js'

import './index.scss'

export default class App extends Component {
    constructor(props) {
	super(props)

	this.state = {
	    currentPage: 'home',
	    showNews: true
	}

	this.switchPage = this.switchPage.bind(this)

	this.socials = [
	    { name: 'instagram', link: 'https://www.instagram.com/mike.kunze510/' },
	    { name: 'facebook', link: 'https://www.facebook.com/MichaelKunzeJ' },
	    { name: 'twitter', link: 'https://twitter.com/The_Kunze' },
	    { name: 'spotlight', link: 'https://www.spotlight.com/1614-5615-0179' },
	    { name: 'youtube', link: 'https://www.youtube.com/channel/UCmEAnMFJhhsyK9vKnLj3Mcw' },
	]

	this.videos = [
	    { title: 'Love you Totes', id: "totes", src: "https://www.youtube.com/embed/SVRyLMnZDsc" },
	    { src: "https://www.youtube.com/embed/LDcueAzYpKo", title: "Where Have All the Trolly's Gone???", id: "trolley" },
	    { id: 'dr_sinister' , src:"https://www.youtube.com/embed/vHVu4YseUrQ", title:"Dr. Sinister Doesn't Have a Philosophy"},
	    { id: 'apocalypse' , src:"https://www.youtube.com/embed/-1JZH2H9UGQ", title:"It's the end of the world and I'm not invited"},
	    { src:"https://www.youtube.com/embed/EIoSVBsDPVA", title:"Open Dad Night", id: 'open_dad_night' },
	    { id: 'too_much_to_touch', src:"https://www.youtube.com/embed/oCtkMIU_LHQ", title:"Michael Kunze - Too Much to Touch (produced by Hugh Edwards)"},
	    { id: 'unfollowing', src:"https://www.youtube.com/embed/wfa1X91lGBU", title:"The Unfollowing"},
	    { id: 'dog-lovers', src:"https://www.youtube.com/embed/Zpp6QvE7_NQ", title:"If dog lovers ruled the world" },
	    { id: 'odg', src:"https://www.youtube.com/embed/6qVpFVMvzLw", title:"Old Dead Girl"},
	    { id: 'leaving_for_work', src:"https://www.youtube.com/embed/blXlhetaA98", title:"Leaving to work"},
	    { id: 'gotcha', src:"https://www.youtube.com/embed/ZEELYGu8ouc", title:"Gotcha!"},
	    { id: 'spyhole', src:"https://www.youtube.com/embed/6bpdNTmBfvg", title:"Spyhole - Hoopla Impro"},
	    { id: 'politeness', src:"https://www.youtube.com/embed/zW-ZDm99NA8", title:"Politeness Game - Hoopla Impro"},
	    { id: 'netflix-mystery', src:"https://www.youtube.com/embed/wtQjTQ80JIU", title:"Too Many Devices: A Netflix Family Mystery"},
	    { id: 'talking-dessert', src:"https://www.youtube.com/embed/7Cw1HYJyXfA", title:"Talking Dessert - Hoopla Impro"},
	    { id: 'vampire-plothole', src:"https://www.youtube.com/embed/NJaA80s_kmo", title:"Vampire Movie Plothole - Hoopla Impro" },
	    { id: 'london-dungeon', src:"https://www.youtube.com/embed/FwrilsZFSwA", title:"The London Dungeon Board Meeting"},
	    { id: 'chosen-one', src:"https://www.youtube.com/embed/WJwvoz18jgs", title:"Why is the weakest character always the Chosen One?" },
	]
	    
	
	this.quoteCarouselText = [
	    {
		name: "reviews-hub",
		link:"https://www.thereviewshub.com/lil-saffron-ragu-to-riches-camden-fringe-the-hen-chickens-theatre/",
		text: "Michael Kunze is a comedy master",
		source: "The Reviews Hub",
		about: "Lil Saffron: Ragu to Riches"
	    },
	    {
		name: "phoenix-remix",
		link: "https://thephoenixremix.com/2021/08/08/review-lil-saffron-ragu-to-riches-the-hen-and-chickens-theatre-camden-fringe-2021/",
		text: "A riotous, enraptured crowd",
		source: "The Phoenix Remix",
		about: "Lil Saffron: Ragu to Riches"
	    },
	    {
		name: "edfringe-review-1",
		link: "https://www.edfringereview.com/review/e/b3Zzhl1doaF4HnLhj03b",
		text: "Damn near-perfect hour of unbridled fun",
		source: "Ed Fringe Review",
		about: "Lil Saffron: Ragu to Riches"
	    },
	    {
		name: "fringe-biscuit",
		link: "https://www.fringebiscuit.com/twittiques/2022/8/12/lil-saffron-ragu-to-riches",
		text: "#Ratatouille meets #Hamilton in a carb-loaded comedy seasoned with delicious puns & lashings of good-cringey rap",
		source: "Fringebiscuit",
		about: "Lil Saffron: Ragu to Riches"
	    },
	    {
		name: "spy-in-the-stalls",
		link: "https://thespyinthestalls.com/2022/08/lil-saffron/",
		text: "Kunze is an energetic and charming comedian",
		source: "Spy in the Stalls",
		about: "Lil Saffron: Ragu to Riches"
	    },
	    {
		name: "musical-theatre-review",
		link: "https://musicaltheatrereview.com/lil-saffron-ragu-to-riches-edinburgh-festival-fringe/",
		text: "Go feed yourself at this banquet of bonkers",
		source: "Musical Theatre Review",
		about: "Lil Saffron: Ragu to Riches"
	    },
	    {
		name: "edfringe-review-2",
		link: "https://www.edfringereview.com/review/e/b3Zzhl1doaF4HnLhj03b",
		text: "A masterclass in comedic storytelling",
		source: "Ed Fringe Review",
		about: "Lil Saffron: Ragu to Riches"
	    },
	    {
		name: "reviews-hub-exile",
		link: "https://www.thereviewshub.com/exile-southwark-playhouse-london/",
		text: "Director Michael Kunze allows the stories to unfold naturally and without pressure to take sides in any argument, yet carefully steers us into empathy",
		source: "ReviewsHub",
		about: "Exile"
	    },
	    {
		name: "everything-theatre-exile",
		link: "https://everything-theatre.co.uk/2021/11/review-exile-lion-and-unicorn-theatre.html",
		text: "The writing, performances, soundscape, and lighting are all perfectly pitched",
		source: "Everything Theatre",
		about: "Exile"
	    },
	    {
		name: "reviews-hub-exile",
		link: "https://www.thereviewshub.com/exile-southwark-playhouse-london/",
		text: "Exile is one of those plays that keeps you thinking about its many layers long beyond the end.",// Home may be where the heart is, but the power of compassion breaks through all boundaries and can bring hope when you least expect it.",
		source: "ReviewsHub",
		about: "Exile"
	    },
	    {
		name: "everything-theatre-exile",
		link: "https://everything-theatre.co.uk/2021/11/review-exile-lion-and-unicorn-theatre.html",
		text: "Exile is a thought-provoking, extremely well-written piece.",
		source: "Everything Theatre",
		about: "Exile"
	    },
	    {
		name: "red-curtain-review",
		link: "",
		text: "Blue Heart Theatre call themselves an 'exciting new company' - which they are showing themselves to be",
		source: "Red Curtain Review",
		about: "Blue Heart Theatre: The Baltimore Waltz"
	    },
	    {
		name: "sunday-independent",
		link: "",
		text: "The five pieces pack a tenaciously realistic punch, and they're quite splendidly performed by the company ensemble",
		source: "Emer O'Kelly - Sunday Independent",
		about: "Blue Heart Theatre: Couples + Pairs"
	    },
	    /*{
		name: "",
		link: "",
		text: "",
		source: "",
		about: ""
	    }*/
	  
	]
    }

    switchPage(id) {
	console.log('setting page:', id)
	this.setState({currentPage: id}) 
    }

    render() {
	const { currentPage, showNews } = this.state

	const newsBanner = showNews ? <NewsBanner text='Solo Debut "Michael Kunze: Infinity Mirror" at Edinburgh Fringe  Aug 1 - 25. Buy your tickets here!' linkUrl='https://underbellyedinburgh.co.uk/event/michael-kunze-infinity-mirror' /> : null	

	const learnMoreOnClick = event => { this.switchPage('infinity-Mirror') }
	const homePage = currentPage == 'home' ? <HomePage socials={this.socials} videos={this.videos} quoteCarouselText={this.quoteCarouselText} underConstruction={false} currentPage={currentPage} learnMoreOnClick={learnMoreOnClick}/> : null

	const videosPage = currentPage == 'videos' ? <VideosPage videosData={this.videos}  currentPage={currentPage}/> : null

	const lilSaffronPage = currentPage == 'lil-Saffron' ? <LilSaffronPage quotes={this.quoteCarouselText} currentPage={currentPage}/> : null

	const infinityMirrorPage = currentPage == 'infinity-Mirror' ? <InfinityMirrorPage quotes={this.quoteCarouselText} currentPage={currentPage}/> : null

	const livePage = currentPage == 'live' ? <LivePage  currentPage={currentPage}/> : null

	const exilePage = currentPage == 'exile' ? <ExilePage quotes={this.quoteCarouselText} currentPage={currentPage}/> : null

	const improvPage = currentPage == 'improv' ? <ImprovPage  currentPage={currentPage}/> : null

	const blueHeartTheatrePage = currentPage == 'blue-Heart-Theatre' ? <BlueHeartTheatrePage  currentPage={currentPage}/> : null

	const contactPage = currentPage == 'contact' ? <ContactPage socials={this.socials}  currentPage={currentPage}/> : null

	const galleryPage = currentPage == 'gallery' ? <GalleryPage  currentPage={currentPage}/> : null

	const header = <Header switchPage={this.switchPage} currentPage={currentPage}/>
	
    	return <div className='app-container'>
		   {newsBanner}
		   {header}
		   {homePage}
		   {videosPage}
		   {lilSaffronPage}
		   {infinityMirrorPage}
		   {livePage}
		   {exilePage}
		   {improvPage}
		   {blueHeartTheatrePage}
		   {contactPage}
		   {galleryPage}
		   </div>
    }
}
