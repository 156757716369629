import React, { Component } from 'react'

import QuoteCarousel from '../QuoteCarousel'
import ImageCarousel from '../Components/ImageCarousel'
import ImageContainer from '../Components/ImageContainer'
import './InfinityMirrorPage.scss'

export default class InfinityMirrorPage extends Component {
    constructor(props) {
	super(props)

	this.photoGallery = [

	    ]
    }



    render() {

	const logoContainer = <div className='infinity-mirror-page__container__about__logos'>
				  <ImageContainer containerClass='logo' id='laughing-horse' />
				  <ImageContainer containerClass='logo' id='free-festival' />
			      </div>

	
	const descriptionContainer = <div className='infinity-mirror-page__container__description'>
					 <h2>Michael Kunze: Infinity Mirror</h2>
					 <p>“You’re only ever one smash hit away<br/>from a Tom Hanks sex party.”</p>
					 <p>2024 Edinburgh Fringe Festival<br/>Underbelly Cowgate Delhi Belly<br/>1 - 25 Aug</p>
					 <p><a href="https://underbellyedinburgh.co.uk/event/michael-kunze-infinity-mirror">Tickets</a></p>
					 <p>Award-winning comic performer, writer and director Michael Kunze (member of the Comedy Lab at the prestigious Torino Film Festival), makes his eagerly anticipated Fringe solo debut as Hollywood film superstar Mitch Coony.</p>
					 <p>Mitch Coony knows what people say about him - that he’s washed up, that he’s bitter and that he’s incredibly handsome and supremely talented - but does anyone know the man behind the myth? No. So, in one-man sketch show Infinity Mirror, (directed by Lee Griffiths), Mitch says it’s time to set the record straight, to shoot from the hip, to get real and
					 tell all. Join Mitch as he traces the arc of his career from rags to riches and back again, taking in the cowboy spectacular, smash hit, off-Broadway show ‘My Brother the Horse’ and Oscar glory in the gritty reimagining of Snow White – ‘Ah-choo: The Sneezy Story’ – to the low moments like ‘Jeffrey Dahmer the Musical!’</p>
					 <p>Along the way, with the help of sketches, songs and top-notch nonsense, Mitch drops names, drops his guard, and drops the ball again and again in his quest for fame.</p>
					 <p>Infinity Mirror is a cautionary tale about celebrity, failure and a man who sold his soul for fame and found it wasn’t worth much to begin with.</p>
				     </div>


	const quotesText = this.props.quotes.filter(quote => { return quote.about == "Lil Saffron: Ragu to Riches" })

    	return <div className='infinity-mirror-page'>
		   <div className='infinity-mirror-page__container'>
		       <ImageContainer id="infinity-mirror-main-poster" />
		       <div className='infinity-mirror-page__container__about'>
			   {descriptionContainer}
		       </div>
		   </div>
	       </div>
    }
}
