import React, { Component } from 'react'

import './ImageCarousel.scss'
import ImageContainer from './ImageContainer.js'

export default class ImageCarousel extends Component {
    constructor(props) {
	super(props)

	this.state = {
	    currentPhotoIndex: 0
	}

	this.clickPrevious = this.clickPrevious.bind(this)
	this.clickNext = this.clickNext.bind(this)
	this.clickThumbnail = this.clickThumbnail.bind(this)
    }

    clickPrevious(event) {
	const currentPhotoIndex = this.state.currentPhotoIndex == 0 ? 0 : this.state.currentPhotoIndex - 1
	this.setState({currentPhotoIndex})
    }

    clickNext(event) {
	const currentPhotoIndex = this.state.currentPhotoIndex == this.props.photoLinks.length ? this.props.photoLinks.length : this.state.currentPhotoIndex + 1
	this.setState({currentPhotoIndex})
    }

    clickThumbnail(currentPhotoIndex) {
	if (this.state.currentPhotoIndex != currentPhotoIndex) this.setState({currentPhotoIndex})
    }

    render() {
	const { carouselId, photoLinks, headline } = this.props
	const { currentPhotoIndex } = this.state

	const nextButtonDisabled = currentPhotoIndex == photoLinks.length - 1
	const nextButtonOnClick = nextButtonDisabled ? null : this.clickNext
	const nextButton = <div className={`image-carousel__container__button fa fa-caret-right${nextButtonDisabled ? ' disabled' : ''}`} onClick={nextButtonOnClick} />

	const previousButtonDisabled = currentPhotoIndex == 0
	const previousButtonOnClick = previousButtonDisabled ? null : this.clickPrevious
	const previousButton = <div className={`image-carousel__container__button fa fa-caret-left${previousButtonDisabled ? ' disabled' : ''}`} onClick={previousButtonOnClick} />

	const photoThumbnails = photoLinks.map((photoLink, i) => {
	    const thumbnailClick = event => { this.clickThumbnail(i) }
	    
	    return <ImageContainer containerClass={currentPhotoIndex == i ? ' selected' : ''} id={`${carouselId}-${i}`} key={`thumbnail-${i}`} onClick={thumbnailClick}/>
	})

	const thumbnailContainer = <div className='image-carousel__thumbnail-container'>
				       {photoThumbnails}
				   </div>

	const currentPhoto = <ImageContainer id={`${carouselId}-${currentPhotoIndex}`}/>

	const currentPhotoContainer = <div className='image-carousel__container'>
					  {previousButton}
					  {currentPhoto}
					  {nextButton}
				      </div>

	const headlineTitle = headline ? <h2>{headline}</h2> : null

	const imageCarousel = <div className='image-carousel'>
				  {headlineTitle}
				  {currentPhotoContainer}
				  {thumbnailContainer}
			      </div>

	return imageCarousel
    }
}
